import React from "react"
import styled from "styled-components"

function Form() {
  return (
    <FormContainer>
      <h1>Contact Me</h1>
      {" "}
      <FormArea
        name="contact"
        action="https://formspree.io/xaybebna"
        method="POST"
      >
        <EmailName>
          <input
            id="email"
            type="email"
            name="email"
            placeholder="email"
            required
          />
          <input
            id="name"
            type="text"
            name="name"
            placeholder="name"
            required
          />
          <textarea
            id="message"
            name="message"
            placeholder="message"
            required
          ></textarea>
          <button type="submit" name="submit">
            <span> Submit</span>
          </button>{" "}
        </EmailName>
      </FormArea>
    </FormContainer>
  )
}

export default Form

const FormContainer = styled.div`
  width:100%;
  h1 {
    margin-top: 2rem;
    color: black;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 7px;
    text-align: center;
    text-transform: uppercase;
  }
  textarea,
  input {
    background: none;
    border: none;
    border-bottom: solid 2px;
    color: white;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    box-sizing: border-box;
  }

  button {
    text-transform: uppercase;
    position: relative;
    margin-top: 1rem;
    text-decoration: none;
    border: 2px solid white;
    text-align: center;
    padding: 5px 0;
    display: inline-block;
    background: #9ED8EB;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: all 0.4s ease 0s;
    width: 100px;
    height: 50px;
    box-sizing: border-box;
&:hover{
background:pink;
}
  }
`

const FormArea = styled.form`
  padding: 2rem;
`
const EmailName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  input,
  textarea {
    margin: 1rem auto;
    font-size: 16px;
    overflow: auto;
    resize: none;
    box-sizing: border-box;
    width: 18em;
  }
  textarea {
    height: 8em;
  }
`
