import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'
import { FaBehance, FaInstagram, FaRegEnvelope } from 'react-icons/fa';
import Form from '../components/contact/form'
const contact = () => {
    return (
        <Layout>
            <SEO title="contact" />
            <Wrapper>
                <h1>Hablemos</h1>
                <h2>Deje su mensaje después de la señal. </h2>
                <IconRow><IconColumn><a href="https://www.behance.net/imyukuri/" aria-label="instagram">
                    <FaBehance />
                </a >
                    <p>@imyukuri</p>
                </IconColumn>
                    <IconColumn><a href="https://www.behance.net/imyukuri/" aria-label="instagram">
                        <FaInstagram /></a>
                        <p> @imyukuri</p>
                    </IconColumn>
                    <IconColumn>
                        <a href="mailto:aileonmarin@gmail.com" aria-label="mail" ><FaRegEnvelope />
                        </a><p>yuku@gmail.com</p>
                    </IconColumn>

                </IconRow>
                <Form />
            </Wrapper>
        </Layout >
    )
}

export default contact
const Wrapper = styled.div`
display: flex;
align-items: center;
flex-direction: column;
background-color: #F4F3F3;
color: black;
width: 100%;
height:100vh;
padding-top:80px;
h1,h2,p{
                    margin:10px auto;
}
a {
    color:  #C2A2C8;
    font-size: 1.6rem;
    &:hover{
    color: #9ED8EB;}
}
`
const IconRow = styled.div`
display:flex;
flex-direction: row;
align-items:space-between;
justify-content: space-evenly;
flex-wrap:wrap;
margin:20px auto;
p{margin:5px 10px;}
`
const IconColumn = styled.div`
display:flex;
flex-direction:column;
align-items: center;
`